import { defineStore } from "pinia";
import { computed, ref } from "vue";

const createBasicAuthToken = (username: string, password: string) => btoa(`${username}:${password}`);

export const useAuth = defineStore(
  "auth",
  () => {
    const managerName = ref<string>();
    const basicAuthToken = ref<string>();
    const guestOrParticipationToken = ref<string>();

    const isManager = computed(() => !!basicAuthToken.value);

    const managerLogin = (username: string, password: string) => {
      managerName.value = username;
      basicAuthToken.value = createBasicAuthToken(username, password);
      window.location.reload();
    };

    const managerLogout = () => {
      managerName.value = undefined;
      basicAuthToken.value = undefined;
      window.location.reload();
    };

    return {
      managerName,
      basicAuthToken,
      guestOrParticipationToken,
      isManager,
      managerLogin,
      managerLogout,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
);
