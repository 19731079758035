import { BrowserTracing } from "@sentry/tracing";
import { init, vueRouterInstrumentation } from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";

import { sentryConfig } from "../config";

const useSentry = (app: App, router: Router) => {
  if (sentryConfig.shouldTrack) {
    // TODO: Add addUserLoaded & addUserUnloaded in case of actual user loading.
    // oidcAuth.events.addUserLoaded((user) => {
    //   Sentry.setUser({ email: user.profile.email, username: user.profile.preferred_username, id: user.profile.sub });
    // });
    // oidcAuth.events.addUserUnloaded(() => {
    //   Sentry.configureScope((scope) => scope.setUser(null));
    // });

    init({
      app,
      dsn: sentryConfig.dsn,
      release: sentryConfig.release,
      environment: sentryConfig.environment,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
          tracePropagationTargets: ["localhost", /^\//], // TODO: Add APIs here to add sentry-trace headers
        }),
      ],
      tracesSampleRate: 1,
      autoSessionTracking: true,
      initialScope: {
        tags: { transaction_id: sentryConfig.transactionId },
      },
    });
  }
};

export { useSentry };
