import type { ValidationArgs } from "@vuelidate/core";
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import type { Ref } from "vue";

import { gettext } from "@/core/translations/index";

export const defineRules = <T extends ValidationArgs>(rules: T) => {
  return rules;
};

const $gettext = gettext.$gettext;

export const required = helpers.withMessage(() => $gettext("Feld darf nicht leer sein."), validators.required);

export const url = helpers.withMessage(() => $gettext("Feld muss eine gültige URL sein."), validators.url);

export const requiredIf = (predicate: () => boolean) =>
  validators.helpers.withMessage(() => $gettext("Feld darf nicht leer sein."), validators.requiredIf(predicate));

export const minLength = (min: number | Ref<number>) =>
  validators.helpers.withMessage(
    (props) =>
      $gettext("Feld muss mindestens %{length} Zeichen lang sein.", {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        length: props.$params.min.toString(),
      }),
    validators.minLength(min),
  );

export const maxLength = (max: number | Ref<number>) =>
  validators.helpers.withMessage(
    (props) =>
      $gettext("Feld darf maximal %{length} Zeichen lang sein.", {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        length: props.$params.max.toString(),
      }),
    validators.maxLength(max),
  );
