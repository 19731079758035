<template>
  <div :class="$style.loadingWrapper" :style="{ '--loading-wrapper-offset': offset ? '1rem' : undefined }">
    <slot />
    <div :class="[$style.overlay, { [$style.visible]: showLoader }]" />
    <div :class="[$style.loaderContainer, { [$style.visible]: showLoader }]">
      <LoadingIndicator />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounce } from "@vueuse/shared";
import { computed } from "vue";

import LoadingIndicator from "@/components/LoadingIndicator.vue";

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    debounceTime?: number;
    offset?: boolean;
  }>(),
  {
    loading: false,
    debounceTime: 200,
    offset: false,
  },
);

const loadingProxy = computed(() => props.loading);

// eslint-disable-next-line vue/no-setup-props-destructure
const showLoader = useDebounce(loadingProxy, props.debounceTime);
</script>

<style module lang="scss">
.loadingWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  --loading-wrapper-offset: 0px;

  .overlay {
    position: absolute;
    top: calc(var(--loading-wrapper-offset) * -1);
    right: calc(var(--loading-wrapper-offset) * -1);
    bottom: calc(var(--loading-wrapper-offset) * -1);
    left: calc(var(--loading-wrapper-offset) * -1);
    padding: var(--loading-wrapper-offset);
    background-color: rgba(255, 255, 255, 0.75);
    opacity: 0;
    transition: opacity 0.25s linear;
    border-radius: $default-border-radius;
    pointer-events: none;
    &.visible {
      opacity: 1;
      transition: opacity 0.5s linear;
      pointer-events: auto;
    }
  }

  .loaderContainer {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.25s;

    &.visible {
      opacity: 1;
      transition: opacity 0.75s ease-in;
    }
  }
}
</style>
