import "cross-fetch/polyfill";

import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { createLink as createUploadLink } from "apollo-absinthe-upload-link/lib";
import { withScalars } from "apollo-link-scalars";
import fetch from "cross-fetch";
import type { IntrospectionQuery } from "graphql";
import { buildClientSchema } from "graphql";
import { DateResolver, DateTimeResolver, NonEmptyStringResolver, URLResolver } from "graphql-scalars";

import introspectionResult from "@/../graphql/introspection.json";
import { apiConfig } from "@/core/config";
import { useAuth } from "@/stores/auth";

import fragmentMatchers from "./helpers/apollo-fragment-matchers";
import type { TypedTypePolicies } from "./helpers/apollo-helpers";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const simpleHttpLink: ApolloLink = createUploadLink({ uri: apiConfig.backend.api, ...fetch });

const authLink = setContext((_, { headers }) => {
  const auth = useAuth();
  let token: string | null = null;

  if (auth.guestOrParticipationToken) {
    token = `Token ${auth.guestOrParticipationToken}`;
  } else if (auth.basicAuthToken) {
    token = `Basic ${auth.basicAuthToken}`;
  }
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      authorization: token ?? undefined,
    },
  };
});

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const typesMap = {
  Date: DateResolver,
  DateTime: DateTimeResolver,
  Uri: URLResolver,
  ID: NonEmptyStringResolver,
};

const scalarsLink = withScalars({
  schema,
  typesMap,
  validateEnums: true,
});

const httpLink = ApolloLink.from([scalarsLink, simpleHttpLink]);

const link = authLink.concat(httpLink);

const typePolicies: TypedTypePolicies = {};

const cache = new InMemoryCache({ typePolicies, possibleTypes: fragmentMatchers.possibleTypes });

export const pullsApiClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    mutate: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
      refetchWritePolicy: "overwrite",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});
