import type { GetTextOptions } from "vue3-gettext";

import { getRuntimeConfig } from "../utils/runtimeData";
import type { RequiredNotNullOrUndefined } from "./../utils/typeHelpers";

// Static values in:
// src/core/config/env.d.ts

type RuntimeConfig = ImportMetaEnv;

const dynamicRuntimeConfig = getRuntimeConfig();

// --- Base Config ---

const defaultBaseConfig: RequiredNotNullOrUndefined<Pick<RuntimeConfig, "BASE_URL" | "NODE_ENV">> = {
  BASE_URL: "/",
  NODE_ENV: "development",
};

const rawBaseConfig = {
  BASE_URL: dynamicRuntimeConfig.BASE_URL
    ? dynamicRuntimeConfig.BASE_URL
    : import.meta.env.BASE_URL
    ? import.meta.env.BASE_URL
    : defaultBaseConfig.BASE_URL,
  NODE_ENV: dynamicRuntimeConfig.NODE_ENV
    ? dynamicRuntimeConfig.NODE_ENV
    : import.meta.env.NODE_ENV
    ? import.meta.env.NODE_ENV
    : defaultBaseConfig.NODE_ENV,
};

const baseConfig = rawBaseConfig;

// --- API Config ---

const defaultApiConfig: RequiredNotNullOrUndefined<
  Pick<RuntimeConfig, "VITE_APP_APIS_BACKEND_API_URL" | "VITE_APP_APIS_BACKEND_WS_URL">
> = {
  VITE_APP_APIS_BACKEND_API_URL: "/", // TODO: Add default value
  VITE_APP_APIS_BACKEND_WS_URL: "/", // TODO: Add default value
};

const rawApiConfig = {
  backend: {
    api: dynamicRuntimeConfig.VITE_APP_APIS_BACKEND_API_URL
      ? dynamicRuntimeConfig.VITE_APP_APIS_BACKEND_API_URL
      : import.meta.env.VITE_APP_APIS_BACKEND_API_URL
      ? import.meta.env.VITE_APP_APIS_BACKEND_API_URL
      : defaultApiConfig.VITE_APP_APIS_BACKEND_API_URL,
    ws: dynamicRuntimeConfig.VITE_APP_APIS_BACKEND_WS_URL
      ? dynamicRuntimeConfig.VITE_APP_APIS_BACKEND_WS_URL
      : import.meta.env.VITE_APP_APIS_BACKEND_WS_URL
      ? import.meta.env.VITE_APP_APIS_BACKEND_WS_URL
      : defaultApiConfig.VITE_APP_APIS_BACKEND_WS_URL,
  },
};

const apiConfig = rawApiConfig;

// --- I18N Config ---

const defaultI18nConfig: RequiredNotNullOrUndefined<Pick<RuntimeConfig, "VITE_APP_I18N_DEFAULT_LANGUAGE">> = {
  VITE_APP_I18N_DEFAULT_LANGUAGE: "de_CH",
};

const rawI18nConfig: Pick<GetTextOptions, "defaultLanguage" | "availableLanguages"> = {
  defaultLanguage: dynamicRuntimeConfig.VITE_APP_I18N_DEFAULT_LANGUAGE
    ? dynamicRuntimeConfig.VITE_APP_I18N_DEFAULT_LANGUAGE
    : import.meta.env.VITE_APP_I18N_DEFAULT_LANGUAGE
    ? import.meta.env.VITE_APP_I18N_DEFAULT_LANGUAGE
    : defaultI18nConfig.VITE_APP_I18N_DEFAULT_LANGUAGE,
  availableLanguages: {
    en_US: "English (United States)",
    de_CH: "German (Switzerland)",
  },
};

const i18nConfig = rawI18nConfig;

// --- Sentry Config ---

const defaultSentryConfig: Pick<
  RuntimeConfig,
  "VITE_APP_SENTRY_ENABLED" | "VITE_APP_SENTRY_DSN" | "VITE_APP_SENTRY_ENVIRONMENT" | "VITE_APP_SENTRY_RELEASE"
> = {
  VITE_APP_SENTRY_ENABLED: "false",
  VITE_APP_SENTRY_DSN: undefined,
  VITE_APP_SENTRY_ENVIRONMENT: undefined,
  VITE_APP_SENTRY_RELEASE: undefined,
};

const rawSentryConfig = {
  enabled: dynamicRuntimeConfig.VITE_APP_SENTRY_ENABLED
    ? dynamicRuntimeConfig.VITE_APP_SENTRY_ENABLED === "true"
    : import.meta.env.VITE_APP_SENTRY_ENABLED
    ? import.meta.env.VITE_APP_SENTRY_ENABLED === "true"
    : defaultSentryConfig.VITE_APP_SENTRY_ENABLED === "true",
  dsn: dynamicRuntimeConfig.VITE_APP_SENTRY_DSN
    ? dynamicRuntimeConfig.VITE_APP_SENTRY_DSN
    : import.meta.env.VITE_APP_SENTRY_DSN
    ? import.meta.env.VITE_APP_SENTRY_DSN
    : defaultSentryConfig.VITE_APP_SENTRY_DSN,
  environment: dynamicRuntimeConfig.VITE_APP_SENTRY_ENVIRONMENT
    ? dynamicRuntimeConfig.VITE_APP_SENTRY_ENVIRONMENT
    : import.meta.env.VITE_APP_SENTRY_ENVIRONMENT
    ? import.meta.env.VITE_APP_SENTRY_ENVIRONMENT
    : defaultSentryConfig.VITE_APP_SENTRY_ENVIRONMENT,
  release: dynamicRuntimeConfig.VITE_APP_SENTRY_RELEASE
    ? dynamicRuntimeConfig.VITE_APP_SENTRY_RELEASE
    : import.meta.env.VITE_APP_SENTRY_RELEASE
    ? import.meta.env.VITE_APP_SENTRY_RELEASE
    : defaultSentryConfig.VITE_APP_SENTRY_RELEASE,
  transactionId: Math.random().toString(36).substring(2, 9),
  silent: false,
};

const sentryConfig = {
  enabled: rawSentryConfig.environment,
  dsn: rawSentryConfig.dsn,
  transactionId: Math.random().toString(36).substring(2, 9),
  release: rawSentryConfig.release,
  environment: rawSentryConfig.environment,
  silent: false,
  shouldTrack:
    rawSentryConfig.enabled &&
    rawSentryConfig.dsn !== undefined &&
    rawSentryConfig.environment !== undefined &&
    rawSentryConfig.release !== undefined,
};

export { apiConfig, baseConfig, i18nConfig, sentryConfig };
export type { RuntimeConfig };
