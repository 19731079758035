import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

import type * as Types from "../../../types/api-types";

export type SurveyOrParticipationQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type SurveyOrParticipationQuery = { __typename?: "RootQueryType" } & {
  node: Types.Maybe<
    | { __typename: "Client" }
    | { __typename: "MultipleChoiceAnswer" }
    | { __typename: "MultipleChoiceQuestion" }
    | ({ __typename: "Participation" } & Pick<Types.Participation, "id" | "participationToken">)
    | { __typename: "Project" }
    | ({ __typename: "Survey" } & Pick<Types.Survey, "id">)
    | { __typename: "TextAnswer" }
    | { __typename: "TextQuestion" }
  >;
};

export const SurveyOrParticipationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "surveyOrParticipation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "node" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Survey" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Participation" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "participationToken" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SurveyOrParticipationQuery, SurveyOrParticipationQueryVariables>;
