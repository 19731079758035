import "./styles/bootstrap.scss";
import "./styles/titles.scss";
import "vue-haystack/style.css";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";

import { provideApis } from "@/api";
import { router } from "@/router";

import App from "./App.vue";
import { useSentry } from "./core/sentry";
import { gettext } from "./core/translations";
import { handleError } from "./core/utils/handleError";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);

provideApis(app.provide.bind(app));

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(gettext);
app.use(router);

useSentry(app, router);

// Default Error Handler
app.config.errorHandler = (error) => handleError(error);
// Handle Errors outside Vue
window.addEventListener("error", (error) => handleError(error));
// Handle errors in Promises that were not caught (for example async watchers)
window.addEventListener("unhandledrejection", (error) => handleError(error));

app.mount("#app");
