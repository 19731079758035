import { createGettext } from "vue3-gettext";

import { i18nConfig } from "../config";
import translations from "./translations.json";

export const gettext = createGettext({
  availableLanguages: i18nConfig.availableLanguages,
  defaultLanguage: i18nConfig.defaultLanguage,
  translations: translations,
  silent: true,
});
