<template>
  <div class="p-l" :class="$style.baseModal">
    <LoadingWrapper :loading="loading" class="p-xl" :class="$style.inner">
      <div class="mb-l d-flex justify-content-between align-items-center">
        <span data-testid="modal_label" :class="$style.label">
          {{ label }}
        </span>
        <SvgIcon v-if="allowClose && closeIcon" role="button" size="26px" icon="x" @click="modal.close()" />
      </div>
      <div :class="$style.content">
        <slot />
      </div>
    </LoadingWrapper>
  </div>
</template>

<script lang="ts" setup>
import { useModal } from "vue-haystack";

import LoadingWrapper from "@/components/LoadingWrapper.vue";
import SvgIcon from "@/components/SvgIcon.vue";

const props = withDefaults(
  defineProps<{
    label: string;
    closeIcon?: boolean;
    loading?: boolean;
    allowClose?: boolean;
  }>(),
  {
    closeIcon: true,
    loading: false,
    allowClose: true,
  },
);

const modal = useModal();

if (!props.allowClose) {
  modal.options.closeOnOverlayClick = false;
}
</script>

<style module lang="scss">
.baseModal {
  width: 100%;
  max-width: 600px;
  max-height: 98vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .inner {
    border-radius: $default-border-radius;
    background-color: $white;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .label {
      @extend %title-four;
    }

    .content {
      overflow: auto;
    }
  }
}
</style>
