<template>
  <BaseModal :allow-close="allowClose" :label="$gettext('Manager Login')">
    <div class="grid gap-xxl" style="--bs-columns: 1">
      <InputText :has-value="!!formState.username" :label="$gettext('Name')" :errors="v$.username.$errors">
        <input v-model="formState.username" type="text" />
      </InputText>
      <InputText :has-value="!!formState.password" :label="$gettext('Passwort')" :errors="v$.password.$errors">
        <input v-model="formState.password" type="password" />
      </InputText>

      <div>
        <MainButton primary @click="submit">
          {{ $gettext("Login") }}
        </MainButton>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useVuelidate } from "@vuelidate/core";
import { ref } from "vue";
import { useModal } from "vue-haystack";

import BaseModal from "@/components/BaseModal.vue";
import InputText from "@/components/InputText.vue";
import MainButton from "@/components/MainButton.vue";
import { required } from "@/core/utils/vuelidate";

withDefaults(
  defineProps<{
    allowClose?: boolean;
  }>(),
  {
    allowClose: true,
  },
);

const modal = useModal();

const formState = ref({
  username: "",
  password: "",
});

const v$ = useVuelidate(
  {
    username: { required },
    password: { required },
  },
  formState,
  { $autoDirty: true },
);

const submit = async () => {
  if (await v$.value.$validate()) {
    modal.close(formState.value);
  }
};
</script>

<style module lang="scss"></style>
