<template>
  <div :class="$style.loadingIndicator" :style="{ '--spinner-width': width }">
    <svg :width="size" :height="size" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle :class="$style.ring" pathLength="1" :r="radius" cx="50%" cy="50%"></circle>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    size?: string;
    width?: string;
  }>(),
  {
    size: "22",
    width: "2",
  },
);

const radius = computed(() => parseInt(props.size) / 2 - parseInt(props.width) / 2);
</script>

<style module lang="scss">
.loadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;

  .ring {
    stroke: currentColor;
    fill: transparent;
    stroke-width: var(--spinner-width);
    transform-origin: 50% 50%;
    animation: spinner 0.75s infinite cubic-bezier(0.46, 0.24, 0.52, 0.73);
    stroke-dasharray: 1;
    stroke-dashoffset: 0.3;
    stroke-linecap: round;
  }

  @keyframes spinner {
    0% {
      transform: rotateZ(deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}
</style>
