<template>
  <div>
    <div v-for="message in messages" :key="message" :class="$style.error">
      {{ message }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ErrorObject } from "@vuelidate/core";
import { computed, unref } from "vue";

const props = withDefaults(
  defineProps<{
    errors: (ErrorObject | string)[];
  }>(),
  {},
);

const messages = computed(() => props.errors.map((e) => (typeof e === "string" ? e : unref(e.$message))));
</script>

<style module lang="scss">
.error {
  color: $signalred;
  font-size: 12px;
}
</style>
