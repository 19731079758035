import { useModalStore } from "vue-haystack";
import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import LoginModal from "@/components/modals/LoginModal.vue";
import { assertIsDefined } from "@/core/utils/typeHelpers";
import { useAuth } from "@/stores/auth";
import { fetchSurveyOrParticipation, useParticipation } from "@/stores/participation/participation";
import { useTenants } from "@/stores/tenants";

export enum ManagerRoutes {
  HOME = "home",
  CLIENT = "client",
  PROJECT = "project",
  SURVEY = "survey",
}

export enum ParticipationRoutes {
  PARTICIPATE = "participate",
  INTRO = "intro",
  QUESTION = "question",
  RESULT = "result",
  OUTRO = "outro",
}

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: ManagerRoutes.HOME,
    component: () => import("./views/clientList/ClientList.vue"),
    meta: {
      auth: "management",
    },
  },
  {
    path: `/client/:id`,
    name: ManagerRoutes.CLIENT,
    component: () => import("./views/clientDetails/ClientDetails.vue"),
    props: true,
    meta: {
      auth: "management",
    },
  },
  {
    path: `/project/:id`,
    name: ManagerRoutes.PROJECT,
    component: () => import("./views/projectDetails/ProjectDetails.vue"),
    props: true,
    meta: {
      auth: "management",
    },
  },
  {
    path: `/survey/:id`,
    name: ManagerRoutes.SURVEY,
    component: () => import("./views/surveyDetails/SurveyDetails.vue"),
    props: true,
    meta: {
      auth: "management",
    },
  },
  {
    path: `/participate`,
    name: ParticipationRoutes.PARTICIPATE,
    children: [
      {
        path: `intro/:surveyId`,
        name: ParticipationRoutes.INTRO,
        component: () => import("./views/participate/intro/IntroView.vue"),
        props: true,
      },
      {
        path: `results`,
        name: ParticipationRoutes.RESULT,
        component: () => import("./views/participate/results/ResultsView.vue"),
      },
      {
        path: `question`,
        name: ParticipationRoutes.QUESTION,
        component: () => import("./views/participate/question/QuestionView.vue"),
      },
      {
        path: `outro`,
        name: ParticipationRoutes.OUTRO,
        component: () => import("./views/participate/outro/OutroView.vue"),
      },
    ],
  },
  // TODO: 404 page
];

if (import.meta.env.DEV === true) {
  routes.push({
    path: `/dev`,
    name: "dev",
    component: () => import("./views/DevView.vue"),
  });
}

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  const auth = useAuth();
  auth.guestOrParticipationToken = undefined;

  if (to.meta.auth === "management") {
    const auth = useAuth();
    const modalStore = useModalStore();

    if (!auth.isManager) {
      modalStore.push(LoginModal, { allowClose: false }).onClose<{
        username: string;
        password: string;
      }>((data) => {
        if (!data) {
          return;
        }
        auth.managerLogin(data.username, data.password);
        window.location.reload();
      });
      next(false);
      return;
    }
  }
  next();
});

router.beforeEach(async (to, _from, next) => {
  const auth = useAuth();
  if (!to.path.startsWith(`/participate`)) {
    useTenants().customerTenants = [];
    useAuth().guestOrParticipationToken = undefined;
    next();
    return;
  }
  const jwt = to.hash.slice(1);
  auth.guestOrParticipationToken = jwt;

  const surveyOrParticipation = await fetchSurveyOrParticipation(jwt);
  assertIsDefined(surveyOrParticipation);
  if (surveyOrParticipation.__typename === "Survey") {
    if (to.name === ParticipationRoutes.INTRO) {
      next();
      return;
    }
    next({ name: ParticipationRoutes.INTRO, params: { surveyId: surveyOrParticipation.id }, hash: to.hash });
    return;
  }
  const participationStore = useParticipation();
  if (surveyOrParticipation.__typename === "Participation") {
    participationStore.participationId = surveyOrParticipation.id;
    next();
    return;
  }
  // TODO: error page?
  throw new Error(`Unexpected error: Expected Survey or Participation, got "${surveyOrParticipation.__typename}"`);
});
